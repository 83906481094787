import { useApi } from '~/composables/api/useApi'

export function useJobs() {
  const { fetchWithAuth } = useApi()
  const runtimeConfig = useRuntimeConfig()
  const RESSOURCE = '/jobs'

  // GET Requests

  async function fetchJob(providedOptions: { jobId: number }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.jobId}`,
      'GET',
      {}
    )
  }

  async function fetchJobsCount(providedOptions: {
    filterByUser?: boolean
    status?: any
    type?: any
    jobTypes?: any
  }) {
    const defaultOptions = { filterByUser: true, status: undefined }
    return await fetchWithAuth(`${RESSOURCE}/count`, 'GET', {
      providedOptions: {
        filterByUser:
          providedOptions.filterByUser,
        status: providedOptions.status || defaultOptions.status,
        type: providedOptions.type,
        jobImpl: providedOptions.jobTypes,
      },
    })
  }

  interface GetJobs {
    index?: number
    nValues?: number
    status?: any
    filterByUser?: boolean
    sortDirection?: string
    sortColumn?: string
    type?: AnalyserNode
    collId?: number
    userid?: number
    jobTypes?: any
  }
  async function fetchJobs(providedOptions: GetJobs) {
    const defaultOptions: GetJobs = {
      status: undefined,
      filterByUser: true,
      sortDirection: 'DESC',
      sortColumn: 'created',
    }
    return await fetchWithAuth(`${RESSOURCE}/list`, 'GET', {
      providedOptions: {
        ...providedOptions,
        jobImpl: providedOptions.jobTypes,
      },
      defaultOptions,
    })
  }

  // POST Requests

  async function undoJob(providedOptions: { jobId: number }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.jobId}/undo`,
      'POST',
      {
        providedOptions,
      }
    )
  }

  async function updateJob(providedOptions: { jobId: number; state?: string }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.jobId}`,
      'POST',
      {
        data: providedOptions.state
          ? { state: providedOptions.state }
          : { state: 'CANCELED' },
      }
    )
  }

  async function killJob(providedOptions: { jobId: number }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.jobId}/kill`,
      'POST'
    )
  }

  // GET Requests Orchestrator
  async function fetchJobsOrchestrator() {
    return await fetchWithAuth(
      `${runtimeConfig.public.REDIRECT_URI}/api/orchestrator/jobs`,
      'GET'
    )
  }

  async function fetchJobOrchestratorLongpoll(jobId: string) {
    return await fetchWithAuth(
      `${runtimeConfig.public.REDIRECT_URI}/api/orchestrator/longpoll/${jobId}`,
      'GET'
    )
  }

  //POST Requests Orchestrator
  async function cancelJobOrchestrator(jobId: string) {
    return await fetchWithAuth(`${runtimeConfig.public.REDIRECT_URI}/api/orchestrator/cancel/${jobId}`,
      'POST',
      {}
    )
  }

  return { fetchJob, undoJob, updateJob, killJob, fetchJobsCount, fetchJobs, fetchJobsOrchestrator, fetchJobOrchestratorLongpoll, cancelJobOrchestrator }
}
